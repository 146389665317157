<template>
    <div>
        <Navbar page="Registro de Operações" link="/entregaveis" nameLink="Entregáveis" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">

            <h1 class="text-3xl mb-5">Registro de Operações</h1>

            <div class="grid grid-cols-12 gap-4 content-center">
                <div class="col-span-3">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300">
                        <h3 class="text-sm md:text-base">Entregável</h3>
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300">
                        <h3 class="text-sm md:text-base">Gerado em</h3>
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300">
                        <h3 class="text-sm md:text-base">Setor</h3>
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="h-12 w-full bg-gray-300 flex flex-col items-center justify-center py-4 text-lg border-2 border-gray-300">
                        <h3 class="text-sm md:text-base">Opções</h3>
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="h-12 w-full bg-yellow-100 flex flex-col items-center justify-center py-4 text-lg border-2 border-yellow-100">
                        <h3 class="text-sm md:text-base">ROPA</h3>
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="h-12 w-full bg-yellow-100 flex flex-col items-center justify-center py-4 text-lg border-2 border-yellow-100">
                        <h3 class="text-sm md:text-base">{{ dataAtualizacao | moment("DD/MM/YYYY HH:mm")  }}</h3>
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="h-12 w-full bg-yellow-100 flex flex-col items-center justify-center py-4 text-lg border-2 border-yellow-100">
                        <select v-model="filtro.setor" name="setor" id="setor" class="m-1 w-full py-2 px-3 border border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm">
                            <option :value="null">Todos</option>
                            <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-span-3">
                    <div class="md:h-12 w-full bg-yellow-100 flex flex-col md:flex-row items-center justify-center py-4 text-base border-2 border-yellow-100">
                        <a target="_blank" rel="noreferrer" :href="`${url_api}/v1/entregaveis/ropaDocx/${$store.state.empresa._id}?setor=${filtro.setor}&token=${$store.state.token}`" class="my-1 text-white text-sm_2 hover:bg-yellow-600 bg-yellow-500 rounded-xl py-1 px-2 md:px-6 mx-1">
                            Visualizar DOCX
                            <img class="h-7 ml-1 inline" :src="require('@/assets/docx.png')" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            dataAtualizacao: null, 
            setores: [],
            filtro: {
                setor: null,
            }
        }
    },
    async beforeMount() {
        const req = await this.$http.get(`/v1/entregaveis/getDataAtualizacao/${this.$store.state.empresa._id}`);
        this.dataAtualizacao = req.data;

        const listSetores = await this.$http.post(`/v1/setores/list`, { all: true});
        this.setores = listSetores.data.data;
    },
}
</script>